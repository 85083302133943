<template>
	<div class="p-grid" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<Toast />
				<DataTable :value="products" :paginator="true" sortField="product_id" :sortOrder="-1"
					class="p-datatable-customers" :rows="10" dataKey="product_id" :rowHover="true" :filters="filters1"
					:loading="loading1">
					<template #header>
						<div class="table-header">
							List of Products
							<Button label="New" icon="pi pi-plus"
								class="p-button-success p-input-icon-left p-mr-2 p-mb*2 m-auto-left"
								@click="openNewModal" />
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters1['global']" placeholder="Global Search" />
							</span>

							<!-- <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" 
										placeholder="Select industries" :filter="true" display="chip"></MultiSelect> -->

						</div>
					</template>
					<!-- <template #empty>
						No products found.
					</template> -->
					<template #loading>
						Loading products data. Please wait.
					</template>
					<Column header="Image">
						<template #body="slotProps">
							<img :src="'https://advantex.uz/public/assets/images/advantex/products/' + slotProps.data.foto"
								:alt="slotProps.data.foto" class="product-image" />
						</template>
					</Column>
					<Column field="product_id" header="ID" :sortable="true" sortField="product_id">
						<template #body="slotProps" index="product_id">
							{{ slotProps.data.product_id }}
						</template>
					</Column>
					<!-- <Column field="product_name" header="Name" :sortable="true" sortField="product_name">
						<template #body="slotProps" index="product_name">
							{{slotProps.data.product_name}}
						</template>
					</Column> -->
					<Column header="Product code" :sortable="true" sortField="product_code" filterField="product_code">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle"
								class="image-text">{{ slotProps.data.product_code }}</span>
						</template>
					</Column>
					<Column header="Group" :sortable="true" sortField="product_group" filterField="product_group">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle"
								class="image-text">{{ slotProps.data.product_group }}</span>
						</template>
					</Column>
					<Column header="Brand" :sortable="true" sortField="product_brand" filterField="product_brand">
						<template #body="slotProps">
							<span style="margin-left: .5em; vertical-align: middle"
								class="image-text">{{ slotProps.data.product_brand }}</span>
						</template>
					</Column>
					<Column field="orig_name" header="Product Type" :sortable="true" sortField="product_type">
						<template #body="slotProps">
							<span>{{ slotProps.data.orig_name }}</span>
						</template>
					</Column>
					<Column field="industry_name" header="Industry" :sortable="true" sortField="product_industry">
						<template #body="slotProps">
							<span v-if="slotProps.data.industries.length > 0">
								{{ slotProps.data.industries[0].name }}...
							</span>
							<span v-else>Null</span>
						</template>
					</Column>
					<Column field="hs_code" header="HS Code" :sortable="true" sortField="hs_code">
						<template #body="slotProps">
							<span>{{ slotProps.data.hs_code }}</span>
						</template>
					</Column>
					<Column field="origin_product" header="Origin" :sortable="true" sortField="origin_product">
						<template #body="slotProps">
							<span :class="' '">{{ slotProps.data.origin_product }}</span>
						</template>
					</Column>
					<Column field="product_width" header="Width" :sortable="true" sortField="product_width">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.product_width }}</span>
						</template>
					</Column>
					<Column field="product_thickness" header="Thickness" :sortable="true" sortField="product_thickness">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.product_thickness }}</span>
						</template>
					</Column>
					<Column field="product_length" header="Length" :sortable="true" sortField="product_length">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.product_length }}</span>
						</template>
					</Column>
					<Column field="product_cost" header="Cost" :sortable="true" sortField="product_cost">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.product_cost }}</span>
						</template>
					</Column>
					<!-- v-if="slotProps.data.price_sum" -->
					<Column field="price_sum" header="Coefficient SUM" :sortable="true" sortField="price_sum">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.price_sum }}</span>
						</template>
					</Column>
					<!-- v-if="slotProps.data.price_usd" -->
					<Column field="price_usd" header="Coefficient USD" :sortable="true" sortField="price_usd">
						<template #body="slotProps">
							<span :class="''">{{ slotProps.data.price_usd }}</span>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center"
						bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success"
								@click="openModal(slotProps.data)"></Button>
						</template>
					</Column>
					<Column headerStyle="width: 8rem; text-align: center"
						bodyStyle="text-align: center; overflow: visible">
						<template #body="slotProps">
							<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-warning"
								@click="openDeleteModal(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>

				<Dialog header="Dialog" v-model:visible="displayEditModal" :style="{ width: '60vw' }" :modal="true"
					:closeOnEscape="false">
					<div class="p-col-12">
						<div class="card">
							<h5 v-if="!displayNewModal">Edit product information</h5>
							<h5 v-else>Add product information</h5>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-6">
									<label for="product_name">Name</label>
									<InputText id="product_name" type="text" v-if="!displayNewModal"
										v-model="selectedProduct.product_name" />
									<InputText id="product_name" type="text" v-else v-model="newProduct.product_name" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_code">Product code</label>
									<InputText id="product_code" type="text" v-if="!displayNewModal"
										v-model="selectedProduct.product_code" />
									<InputText id="product_code" type="text" v-else v-model="newProduct.product_code" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_group">Group</label>
									<InputText id="product_group" rows="4" v-if="!displayNewModal"
										v-model="selectedProduct.product_group" />
									<InputText id="product_group" rows="4" v-else v-model="newProduct.product_group" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_brand">Product Brand</label>
									<Dropdown id="product_brand" v-model="productBrandOption" :options="brandOptions"
										optionLabel="name" dataKey="id" placeholder="Select One" :editable="false" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_type">Product Type</label>
									<Dropdown id="product_type" v-model="productTypeOption"
										:options="productTypeOptions" optionLabel="orig_name" dataKey="id"
										placeholder="Select One" :editable="false" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="industry_id">Product Industry</label>
									<MultiSelect v-model="industryMultiOption" :options="industryOptions"
										optionLabel="name" dataKey="id" placeholder="Select industries" :filter="true"
										display="chip"></MultiSelect>
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="hs_code">HS Code</label>
									<InputText id="hs_code" v-if="!displayNewModal" v-model="selectedProduct.hs_code">
									</InputText>
									<InputText id="hs_code" v-else v-model="newProduct.hs_code" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="origin_product">Origin</label>
									<InputText id="origin_product" v-if="!displayNewModal"
										v-model="selectedProduct.origin_product"></InputText>
									<InputText id="origin_product" v-else v-model="newProduct.origin_product" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_width">Width</label>
									<InputText id="product_width" v-if="!displayNewModal"
										v-model="selectedProduct.product_width"></InputText>
									<InputText id="product_width" v-else v-model="newProduct.product_width" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_profile">Profile</label>
									<InputText id="product_profile" v-if="!displayNewModal"
										v-model="selectedProduct.product_profile"></InputText>
									<InputText id="product_profile" v-else v-model="newProduct.product_profile" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_diameter">Diameter</label>
									<InputText id="product_diameter" v-if="!displayNewModal"
										v-model="selectedProduct.product_diameter"></InputText>
									<InputText id="product_diameter" v-else v-model="newProduct.product_diameter" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_thickness">Thickness</label>
									<InputText id="product_thickness" v-if="!displayNewModal"
										v-model="selectedProduct.product_thickness"></InputText>
									<InputText id="product_thickness" v-else v-model="newProduct.product_thickness" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_length">Length</label>
									<InputText id="product_length" v-if="!displayNewModal"
										v-model="selectedProduct.product_length"></InputText>
									<InputText id="product_length" v-else v-model="newProduct.product_length" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="product_cost">Cost</label>
									<InputText id="product_cost" v-if="!displayNewModal"
										v-model="selectedProduct.product_cost"></InputText>
									<InputText id="product_cost" v-else v-model="newProduct.product_cost" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="price_usd">Coefficient USD</label>
									<InputText id="price_usd" v-if="!displayNewModal"
										v-model="selectedProduct.price_usd"></InputText>
									<InputText id="price_usd" v-else v-model="newProduct.price_usd" />
								</div>
								<div class="p-field p-col-12 p-md-6">
									<label for="price_sum">Coefficient SUM</label>
									<InputText id="price_sum" v-if="!displayNewModal"
										v-model="selectedProduct.price_sum"></InputText>
									<InputText id="price_sum" v-else v-model="newProduct.price_sum" />
								</div>
								<div class="p-field p-col-12 p-md-12">
									<label for="product_image">Product image</label>
									<br>
									<img v-if="selectedProduct.foto"
										:src="'https://advantex.uz/public/assets/images/advantex/products/' + selectedProduct.foto"
										:alt="selectedProduct.foto" class="product-image-modal"
										ref="modalProductImage" />
									<label v-else class="italic"> No photo </label> <br /> <br />
									<input type="file" id="product_image" ref="file" @change="handleFileUpload" />
									<!-- <Button label="+ Choose file" class="p-button-secondary" @click="onClickSelectImage" /> -->
								</div>
								<div class="p-field p-col-12 p-md-12">
									<label for="caption">Caption</label>
									<InputText id="caption" v-if="!displayNewModal" v-model="selectedProduct.caption">
									</InputText>
									<InputText id="caption" v-else v-model="newProduct.caption" />
								</div>
								<div class="p-field p-col-12 p-md-12">
									<label for="description">Description</label>
									<InputText id="description" v-if="!displayNewModal"
										v-model="selectedProduct.description"></InputText>
									<InputText id="description" v-else v-model="newProduct.description" />
								</div>

							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Save" @click="insertProduct" icon="pi pi-check" class="p-button-primary" />
						<Button label="Dismiss" @click="closeModal" icon="pi pi-times" class="p-button-secondary" />
					</template>
				</Dialog>

				<Dialog header="Dialog" v-model:visible="displayDeleteModal" :style="{ width: '30vw' }" :modal="true">
					<p>
						Are you sure to delete?
					</p>
					<template #footer>
						<Button label="Yes" @click="deleteProduct" icon="pi pi-check" class="p-button-secondary" />
						<Button label="No" @click="closeDeleteModal" icon="pi pi-times" class="p-button-secondary" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>

import ProductService from "../service/ProductsService";
import ProductTypeService from "../service/ProductTypeService";
import IndustryService from "../service/IndustriesService";

export default {
	data() {
		return {
			products: [],
			selectedProduct: { name: '', email: '', address: '', representative: '', phone: '' },
			filters1: {},
			filters2: {},
			loading1: true,
			expandedRows: [],
			displayEditModal: false,
			displayNewModal: false,
			displayDeleteModal: false,
			user_admin: false,
			newProductImage: '',
			newProduct: {
				foto: '', hs_code: '', origin_product: '', product_code: '', product_cost: '', product_group: '',
				product_length: '', product_name: '', product_thickness: '', product_type: '', product_width: ''
			},
			componentKey: 0,
			newImageURL: null,
			industryOptions: [],
			productTypeOptions: [],
			brandOptions: [],
			productBrandOption: {},
			industryOption: null,
			productTypeOption: {},

			industryMultiOption: [],
			product_related_industries: [],
		}
	},
	productService: null,
	industryService: null,
	productTypeService: null,

	created() {
		this.productService = new ProductService();

		this.industryService = new IndustryService();

		this.productTypeService = new ProductTypeService();

	},

	mounted() {
		this.onMounted()
	},

	updated() {
	},
	methods: {
		onMounted() {
			this.productService.getProductsAll().then(data => {
				if (data.isLogged) {
					this.products = data.fetch_data;
					// console.log(this.products);
					this.loading1 = false;
				}
				else {
					this.$router.push('Login')
				}
			});

			this.industryService.getIndustries().then(data => {
				this.industryOptions = data.fetch_data
				// console.log(this.industryOptions)
			})

			this.productTypeService.getProductTypes().then(data => {
				this.productTypeOptions = data.fetch_data
			})

			this.productTypeService.getProductBrands().then(data => {
				this.brandOptions = data.fetch_data
			})

		},

		handleFileUpload(event) {
			const file = event.target.files[0]
			this.url = URL.createObjectURL(file)

			if (this.displayNewModal) {
				this.newProduct.foto = event.target.files[0] //this.$refs.file.files[0]
			}
			else {
				this.selectedProduct.foto = event.target.files[0]
			}
		},

		openModal: function (rowId) {
			this.selectedProduct = rowId;
			this.industryOption = { id: this.selectedProduct.industry_id, name: this.selectedProduct.industry_name }
			this.selectedProduct.industries.forEach(element => {
				this.industryMultiOption.push({ id: element.id, name: element.name })
			});
			this.productTypeOption = { id: this.selectedProduct.product_type_id, orig_name: this.selectedProduct.orig_name }
			this.productBrandOption = { id: this.selectedProduct.product_brand_id, name: this.selectedProduct.product_brand }
			this.displayEditModal = true;
		},

		closeModal() {
			// console.log(this.industryMultiOption)
			this.displayEditModal = false;
			this.selectedProduct = {};
			this.industryMultiOption = []
			if (this.displayNewModal == true) {
				this.displayNewModal = false;
			}

		},

		openDeleteModal: function (rowId) {

			this.selectedProduct = rowId;
			this.displayDeleteModal = true;
		},

		closeDeleteModal() {
			this.displayDeleteModal = false;
			this.selectedProduct = {};
		},

		deleteProduct() {
			this.productService.deleteProduct(this.selectedProduct)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {
					this.closeDeleteModal();
					return response
				})
				.then(response => {
					this.$toast.add({ severity: 'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000 });
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				})
				.catch(error => {
					console.log(error)
				})

		},

		openNewModal() {
			this.industryOption = {}
			this.industryMultiOption = []
			this.selectedProduct = {}
			this.productTypeOption = {}
			this.productBrandOption = {}
			this.displayEditModal = true;
			this.displayNewModal = true;

		},

		insertProduct() {
			if (this.displayNewModal) {
				this.newProduct.industries = this.industryMultiOption
				this.newProduct.product_type_id = this.productTypeOption.id
				this.newProduct.product_brand_id = this.productBrandOption.id
				this.productService.insertProduct(this.newProduct)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.closeModal()
						return response
					})
					.then(response => {
						this.$toast.add({ severity: 'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000 });
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					})
					.catch(error => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
						console.log(error)
					})
			}
			else {
				this.selectedProduct.industries = this.industryMultiOption
				this.selectedProduct.product_type_id = this.productTypeOption.id
				this.selectedProduct.product_brand_id = this.productBrandOption.id
				console.log(this.selectedProduct)
				this.productService.updateProduct(this.selectedProduct)
					.then(response => {
						console.log(response)
						return response
					})
					.then(response => {
						this.closeModal()
						return response
					})
					.then(response => {
						this.$toast.add({ severity: 'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000 });
						return response
					})
					.then(response => {
						this.onMounted()
						return response
					})
					.catch(error => {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
						console.log(error)
					})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.multiselect-custom {
	.p-multiselect-label:not(.p-placeholder) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}
}

::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead>tr>th {
		text-align: left;
	}

	.p-datatable-tbody>tr>td {
		cursor: auto;
		overflow: hidden;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.product-image-modal {
	width: 150px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin-right: 2rem;
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {

			.p-datatable-thead>tr>th,
			.p-datatable-tfoot>tr>td {
				display: none !important;
			}

			.p-datatable-tbody>tr {
				>td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
